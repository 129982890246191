import { Button, Divider, FormControl, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Switch } from "@mui/material";
import React from "react";
import { areas, cities, http, lines, loading, user } from "../../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircle, Create, Delete } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
export default class Area extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({cities:[],lines:[],areas:[],name:"",city_id:"",line_id:"",input:"",user:null,price:1000
        ,add:false,edit:false,edit_item:{name:""}})
        this.getData()
        cities.subscribe(res=>this.setState({cities:res}))
        lines.subscribe(res=>this.setState({lines:res}))
        areas.subscribe(res=>this.setState({areas:res}))
    }


    getData()
    {
        http.get('cities').then(res=>{
            cities.next(res.data)
            localStorage.setItem('cities',JSON.stringify(res.data))
        }).catch(e=>console.log(e))
        http.get('lines').then(res=>{
            console.log(res.data)
            lines.next(res.data)
            localStorage.setItem('lines',JSON.stringify(res.data))
        }).catch(e=>console.log(e))
        http.get('areas').then(res=>{
            areas.next(res.data)
            localStorage.setItem('areas',JSON.stringify(res.data))
        }).catch(e=>console.log(e))
    }
 
    delete(item)
    {
      http.delete('areas/'+item.id).then(res=>this.getData())
    }
  
    componentDidMount()
    {
       user.subscribe(res=>this.setState({user:res}))
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('areas',{name:this.state.name,city_id:this.state.city_id,line_id:this.state.line_id}).then(res=>{
            this.setState({name:""})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("areas/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})

    }

    render()
    {
        return (<>
     
        <Dialog open={this.state.add}  onClose={()=>this.setState({add:false})}>
        <DialogTitle>اضافة منطقة</DialogTitle>
        <DialogContent>
        
          <TextField autoFocus margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />

<TextField autoFocus margin="dense"  value={this.state.price}  
          onChange={(e)=>this.setState({price:e.target.value})}
           label="السعر" fullWidth variant="standard"   />

        <FormControl margin="dense" fullWidth variant="standard" >
        <InputLabel >المدينة</InputLabel>
        <Select
          value={this.state.city_id}
          onChange={(e)=>{this.setState({city_id: e.target.value})}}
          label="المدينة"
        >
         {this.state.cities&&this.state.cities.map((item,key) =>{

         return <MenuItem key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
      <FormControl margin="dense" fullWidth variant="standard" >
        <InputLabel >الخط</InputLabel>
        <Select
          value={this.state.line_id}
          onChange={(e)=>{this.setState({line_id: e.target.value})}}
          label="الخط"
        >
         {this.state.lines&&this.state.lines.map((item,key) =>{

         return <MenuItem key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name||!this.state.city_id||!this.state.line_id} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل منطقة </DialogTitle>
        <DialogContent>
        
          <TextField autoFocus margin="dense" value={this.state.edit_item.name}  
          onChange={(e)=>{
              var edit=this.state.edit_item 
              edit.name=e.target.value 
              this.setState({edit_item:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />

<TextField autoFocus margin="dense" className="my-2" value={this.state.edit_item.price}  
          onChange={(e)=>{
              var edit=this.state.edit_item 
              edit.price=e.target.value 
              this.setState({edit_item:edit})
          }}
          label="السعر" fullWidth variant="standard"   />
          <FormControl margin="dense" fullWidth variant="standard" >
        <InputLabel >المدينة</InputLabel>
        <Select
          value={this.state.edit_item.city_id}
          onChange={(e)=>{
            var edit=this.state.edit_item 
            edit.city_id=e.target.value 
            this.setState({edit_item:edit})
          }}
          label="المدينة"
        >
         {this.state.cities&&this.state.cities.map((item,key) =>{

         return <MenuItem  key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
      <FormControl margin="dense" fullWidth variant="standard" >
        <InputLabel >الخط</InputLabel>
        <Select
          value={this.state.edit_item.line_id}
          onChange={(e)=>{
            var edit=this.state.edit_item 
            edit.line_id=e.target.value 
            this.setState({edit_item:edit})
          }}
          label="الخط"
        >
         {this.state.lines&&this.state.lines.map((item,key) =>{

         return <MenuItem  key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_item:{name:""}})}>الغاء</Button>
          <Button 
          disabled={!this.state.edit_item.name||!this.state.edit_item.city_id||!this.state.edit_item.line_id}
          onClick={()=>{
              http.put('areas/'+this.state.edit_item.id,this.state.edit_item)
              .then(res=>{
                this.setState({edit:false,edit_item:{name:"",city_id:"",line_id:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_item:{name:"",city_id:"",line_id:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>
           
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>
    :null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      placeholder="بحث "
      onChange={(e)=>this.setState({input:e.target.value})}
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>
            <div class="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>المنطقة</th>
                        <th>المدينة</th>
                        <th>الخط</th>
                        <th>السعر</th>
                        <th>الحالة</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.areas&&this.state.areas.filter(item=>item.name.indexOf(this.state.input) >-1)
                    .map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>{item.city.name} </td>
                            <td>{item.line.name} </td>
                            <td>{item.price} </td>
                            <td>
                            {this.state.user&&this.state.user.role_id<3?
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            :null}
                            </td>
                            {this.state.user&&this.state.user.role_id<3?
                            <td><IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_item:item})
                            console.log(item)
                            }}>
                                 <Create /> </IconButton>
                                 <IconButton color="error" onClick={()=>this.delete(item)}>
                                   <Delete></Delete>
                                 </IconButton>
                                  </td>
                                  :<td></td>}
                        </tr>)
                    })}
                </tbody>
            </table>
            </div>
        </>)
    }
}