
import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DialogTitle from '@mui/material/DialogTitle';
import SelectTable from "../../components/SelectTable";
import { Close } from "@mui/icons-material";
import '../../Rtl.css';
import {
    BrowserRouter as Router,
  
    Route,
    useParams
  } from "react-router-dom";
import { http } from "../../service";

  



export default class AppUpdate extends React.Component
{
    
    constructor(props)
    {
        var id=window.location.pathname.replace("/app/update/","")
        console.log(id)
        super(props)
        this.state={customer_id:'',pilots:[],area_id:"",delivery_date:"",view_areas1:false,mobile:"",mobile1:"",amount:0,order:null,
        address:"",customers:[],customer_list:[],areas:[],products:[],propduct_id:"",delivery:0,channels:[],channel_id:'',
        view_customers:false,customer:null,view_areas:false,delivery_area_id:"",client_address:"",client_name:"",
        id:id,statuses:[],
        client_mobile:"",client_mobile1:"",description:"",note:"",expected_delivery_date:"",pickup_date:"",items:[]
      }
      
      
        
    }

    componentDidMount()
    {
      http.get("pilots").then(res=>{
        this.setState({pilots:res.data})
    }).catch(e=>console.log(e))
      this.getCustomers()
    }

    selectCustomer(item){
      this.setState({
        customer_id:item.id,
        area_id:item.area_id,
        address:item.address,
        view_customers:false,
        customer:item
      })
    }

    getCustomers()
    {

        http.get("orders/"+this.state.id).then(res=>{
            console.log(res.data)
            this.setState({order:res.data})
        }).catch(e=>console.log(e))

        http.get("statuses").then(res=>{
            this.setState({statuses:res.data})
        }).catch(e=>console.log(e))

        http.get("customers").then(res=>{
            this.setState({customers:res.data,customer_list:res.data})
        }).catch(e=>console.log(e))

        http.get("channels").then(res=>{
          this.setState({channels:res.data})
          console.log(this.state.channels)
      }).catch(e=>console.log(e))

        http.get("products").then(res=>{
          this.setState({products:res.data})
      }).catch(e=>console.log(e))

        http.get("areas").then(res=>{
          this.setState({areas:res.data})
      })
    }

    save()
    {
      http.put('orders/'+this.state.id,this.state.order).then(res=>{
        window.location.href='app/orders'
      }).catch(e=>console.log(e))
    }

    setArea(item)
    {
      this.setState({area_id:item.id,view_areas:false})
    }

    render()
    {
        return (
            <div className="w-100">
            {this.state.order
             ?
          <>
            <Dialog open={this.state.view_customers} onClose={()=>this.setState({view_customers:false})} fullWidth>
          <DialogActions>
          <IconButton onClick={()=>{this.setState({view_customers:false})}} > <Close></Close></IconButton>
         
        </DialogActions>
        <DialogTitle className="text-center"><h1>التاجر</h1></DialogTitle>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"الشركة",key:'name'},
          {label:"رقم الهاتف",key:'mobile'},
          {label:"العنوان",key:'address'},
        ]} data={this.state.customers}
         handler={(e)=>{this.setState({customer_id:e.id,view_customers:false})}} />
        </DialogContent>
        
      </Dialog>

      <Dialog open={this.state.view_areas} onClose={()=>this.setState({view_areas:false})} fullWidth>
         
         <div className="flex">
          <IconButton onClick={()=>{this.setState({view_areas:false})}} > <Close></Close></IconButton>
         
        
        <DialogTitle className="text-center"><h1>المنطقة</h1></DialogTitle>
        </div>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"المنطقية",key:'name'},
        ]} data={this.state.areas}
         handler={(e)=>{this.setArea(e)}} />
        </DialogContent>
        
      </Dialog>

      <Dialog open={this.state.view_areas1} onClose={()=>this.setState({view_areas1:false})} fullWidth>
         
         <div className="flex">
          <IconButton onClick={()=>{this.setState({view_areas1:false})}} > <Close></Close></IconButton>
         
        
        <DialogTitle className="text-center"><h1>المنطقة</h1></DialogTitle>
        </div>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"المنطقية",key:'name'},
        ]} data={this.state.areas}
         handler={(e)=>{this.setState({area_id:e.id,view_areas1:false})}} />
        </DialogContent>
        
      </Dialog>
            <div className="w-100 p-2" style={{direction:"rtl",textAlign:"right"}}>
               
                <div className="main">
                <h2 className="my-3 font-bold"> تعديل طلب</h2>
                <label className="mt-1 ">  كود الطلب</label>
    <input className="form-control mb-2" placeholder="كود الطلب" value={this.state.order.code} onChange={(e)=>{
         var order=this.state.order 
         order.code=e.target.value
      this.setState({order:order})
    }} />
    <label>حالة الطلب</label>
    <select className="form-select mb-2" value={this.state.order.status_id} onChange={(e)=>{
          var order=this.state.order 
          order.status_id=e.target.value
       this.setState({order:order})
      }} >
        <option value=''>حالة الطلب</option>
        {this.state.statuses.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>

                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }} >
      <select className="form-select" value={this.state.order.customer_id} onChange={(e)=>{
          var order=this.state.order 
          order.customer_id=e.target.value
       this.setState({order:order})
      }} >
        <option value=''>التاجر</option>
        {this.state.customers.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>
      
     
      
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={(e)=>{this.setState({view_customers:true})}}>
        <SearchIcon />
      </IconButton>
     
    </Paper>

    <label className="mt-1">المندوب</label>
    <select className="form-select mb-1" value={this.state.order.pilot_id} 
         onChange={(e)=>{
          var order=this.state.order 
          order.pilot_id=e.target.value
       this.setState({order:order})
      }}
       >
        <option value=''>المندوب</option>
        {this.state.pilots.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>


  
    <div className="row">
      <div className="col-6">
    <label className="mt-1 ">رقم هاتف العميل</label>
    <input className="form-control" placeholder="رقم هاتف العميل" value={this.state.order.mobile} 
    onChange={(e)=>{
        var order=this.state.order 
        order.mobile=e.target.value
     this.setState({order:order})
    }} />
    </div><div className="col-6">
    <label className="mt-1 ">رقم هاتف اخر العميل</label>
    <input className="form-control" placeholder="رقم هاتف اخر العميل" value={this.state.order.mobile1} 
     onChange={(e)=>{
        var order=this.state.order 
        order.mobile1=e.target.value
     this.setState({order:order})
    }}
     />
    </div></div>
    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',marginTop:".5rem" }}  >
      <select className="form-select" value={this.state.order.area_id} 
       onChange={(e)=>{
        var order=this.state.order 
        order.area_id=e.target.value
     this.setState({order:order})
    }} >
        <option value=''>المنطقة</option>
        {this.state.areas.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>({item.line.name}) {item.name}</option>)
        })}
      </select>
      
     
      
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={(e)=>{this.setState({view_areas1:true})}}>
        <SearchIcon />
      </IconButton>
     
    </Paper>
    <label className="mt-1 ">عنوان العميل</label>
    <input className="form-control" placeholder="العنوان" value={this.state.order.address} 
    onChange={(e)=>{
        var order=this.state.order 
        order.address=e.target.value
     this.setState({order:order})
    }}
     />

 
  
    <table className="table">
      <thead>
        <tr>
          <th>رسوم التوصيل</th>
          <th>قيمة الطلب</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          <input className="form-control" value={this.state.order.delivery} type="number" 
           onChange={(e)=>{
            var order=this.state.order 
            order.delivery=e.target.value
            this.setState({order:order})}}  />
          </td>
          <td>
            <input className="form-control" value={this.state.order.amount} type="number" 
            onChange={(e)=>{
                var order=this.state.order 
                order.amount=e.target.value
                this.setState({order:order})
            }} />
          </td>
          
        </tr>
      </tbody>
    </table>
    <label className="mt-2">ملاحظات</label>
    <textarea className="form-control " value={this.state.order.note} 
    onChange={(e)=>{
        var order=this.state.order 
        order.note=e.target.value
        this.setState({order:order})
    }}
    ></textarea>
    <label className="mt-2">تاريخ التأجيل</label>
    <input className="form-control" type="date" value={this.state.order.new_date}
    onChange={(e)=>{
      this.state.order.new_date=e.target.value 
    this.setState({order:this.state.order})
    }}
     />

<label >تمت التسوية</label>
<Switch  checked={this.state.order.is_paid==1} 
onChange={(e)=>{
  this.state.order.is_paid=this.state.order.is_paid==1?0:1
  this.setState({order:this.state.order})
}}  />


<br/>

<label >تمت تحويل لطلب</label>
<Switch  checked={this.state.order.is_pending==0} 
onChange={(e)=>{
  this.state.order.is_pending=this.state.order.is_pending==1?0:1
  this.setState({order:this.state.order})
}}  />


<br/>


            <button
            onClick={()=>this.save()}
             disabled={!this.state.order.area_id||!this.state.order.address||this.state.order.delivery<1||!this.state.order.mobile||!this.state.order.customer_id||!this.state.order.pilot_id}
             className="py-1 my-2 px-3 bg-slate-500 hover:bg-slate-600 rounded-md text-white">حفظ</button>
               
               <p>ليس بالامكان التحويل لطلب مالم يتم اختيار مندوب</p>
                </div>
            </div>
            </>
            :null}
            </div>
        )

    }
}