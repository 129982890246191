import { AddCircle, BarChart, Create, Delete, LoginOutlined, Search, WhatsApp } from "@mui/icons-material";
import {  FormControl, IconButton, InputAdornment, InputLabel, Button, Typography,Modal,Box, Select, MenuItem, Paper, InputBase, Divider } from "@mui/material";
import React from "react";
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { areas, customers, http, loading ,user} from "../service";
import SearchIcon from '@mui/icons-material/Search';
import RestoreIcon from '@mui/icons-material/Restore';
export default class Customers extends React.Component{
  customer={name:"",mobile:"",mobile1:"",address:"",person:"",area_id:"",email:"",}
    constructor(props)
    {
        super(props)
        this.state={addModal:false,customers:[],c:null,
          restore:false,
          rows : [],cities:[],areas:[],edit:false,input:"",
          name:"",mobile:"",mobile1:"",address:"",person:"",area_id:"",email:"",bank:"",account:""
          ,customer:{ name:"",mobile:"",mobile1:"",address:"",person:"",area_id:"",email:""},user:null,
          columns:[]}

          
        const { data } = {
            dataSet: 'Employee',
            visibleFields: this.VISIBLE_FIELDS,
            rowLength: 100,
          };
          this.getData()
          
          
    }

    getData()
    {
      http.get("customers").then(res=>{
        this.setState({customers:res.data})
      }).catch(e=>console.log(e))
      http.get("areas").then(res=>{
        this.setState({areas:res.data})
      }).catch(e=>console.log(e))
    }
    delete(item){
      http.delete("customers/"+item.id).then(res=>this.getData())
    }
    componentDidMount()
    {
     user.subscribe(res=>this.setState({user:res}))

     this.setState({columns: [
      { field: 'code', headerName: 'كود العميل' },
      { field: 'name', headerName: 'اسم المتجر' },
      { field: 'person', headerName: 'الاسم' },
      { field: 'mobile', headerName: 'رقم الهاتف',width:150,renderCell:(data)=>(
        <>
      
       <IconButton color="success"
             onClick={()=>{
              window.open("https://wa.me/249"+data.row.mobile.substring(1))
            }}>
              <WhatsApp />
            </IconButton>
            { data.row.mobile }
        </>
      ) },
      { field: 'mobile1', headerName: 'رقم هاتف اخر' },
      { field: 'address', headerName: 'العنوان' },
      { field: 'area', headerName: 'المنطقة',renderCell: (params) => (
        <>
          {
            params?.row?.area?.name
          }
        </>
      ), },
      { field: 'account', headerName: 'رقم الحساب' },

      {
          field: 'actions',
          type: 'actions',
          getActions: (params) =>  this.state.user&&this.state.user.role_id<3?[
           
            <GridActionsCellItem style={{width:"100%"}} icon={<Create />}  
             onClick={()=>{
               
               this.setState({customer:params.row,edit:true})
              }
              }  label="update"  />
               
            
          ]:[]
        },
        { field: 'delete',width:150, headerName: '',renderCell: (params) => this.state.user&&this.state.user.role_id<3? (
          <>
          <IconButton  onClick={()=>this.setState({c:params.row,restore:true})}>
          <RestoreIcon></RestoreIcon>
        </IconButton>
           <IconButton color="secondary" onClick={()=>this.setState({item:params.row,report:true})}>
          <BarChart />
        </IconButton>
          <IconButton color="error" onClick={()=>this.delete(params.row)}>
          <Delete></Delete>
        </IconButton>

        
        </>

        ):(<></>)
      }
        
    ]})

    }
    add()
    {
      this.setState({addModal:false})
      loading.next(true)
      http.post('customers',this.state)
      .then(res=>{
        loading.next(false)
        this.setState({ name:"",user:null,mobile:"",mobile1:"",address:"",person:"",area_id:"",email:"",report:false,start:"",end:""})
        this.getData()
      })
      .catch(e=>{
        loading.next(false)
        console.log(e)
      })
    }

    componentWillUnmount()
    {
      user.subscribe(res=>this.setState({user:res}))
    }
    login()
    {
        loading.next(true)
        http.post("auth/login",this.state)
        .then(res=>{
            loading.next(false)
            console.log(res.data)
            http.defaults.headers['Authorization']="Bearer "+res.data.token
            user.next(res.data.user)
            localStorage.setItem('user',JSON.stringify(res.data.user))
           window.location.replace("/")
            
        })
        .catch(e=>{
            loading.next(false)
            if(e)
            {
            let error=[]
            for(let i in e?.response?.data )
            {error.push(e?.response?.data[i])}
            this.setState({errors:error})
            }
           
        })
    }

    update(item)
    {
      this.setState({edit:false})
      loading.next(true)
      http.put('customers/'+item.id,item).then(res=>{
        loading.next(false)
        this.getData()
      })
      .catch(e=>{
        loading.next(false)
        console.log(e)
      })
    }

    render()
    {
        return (
            <>
              <Dialog  open={this.state.restore} onClose={()=>this.setState({restore:false})}>
        <DialogTitle> تعيين كلمة مرور</DialogTitle>
        <DialogContent>
        
          <TextField type="password"  margin="dense"  value={this.state.c?.password}  
          onChange={(e)=>{
            this.state.c.password=e.target.value;
            this.setState({c:this.state.c})
          }}  
          InputLabelProps={{
            shrink: true,
          }}
           label="كلمة المرور" fullWidth variant="standard"   />

        


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({restore:false})}>الغاء</Button>
          <Button onClick={()=>{
            http.put("customers/"+this.state.c.id,{password:this.state.c.password})
              this.setState({restore:false})}}>تنفيذ</Button>
        </DialogActions>
      </Dialog>

            <Dialog  open={this.state.report} onClose={()=>this.setState({report:false})}>
        <DialogTitle>تقرير تاجر</DialogTitle>
        <DialogContent>
        
          <TextField type="date"  margin="dense"  value={this.state.start}  
          onChange={(e)=>this.setState({start:e.target.value})}  InputLabelProps={{
            shrink: true,
          }}
           label="البداية" fullWidth variant="standard"   />

          <TextField type="date"  margin="dense"  value={this.state.end}  
          onChange={(e)=>this.setState({end:e.target.value})}  InputLabelProps={{
            shrink: true,
          }}
           label="النهاية" fullWidth variant="standard"   />


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({report:false})}>الغاء</Button>
          <Button onClick={()=>{
             window.open('https://backend.jai3leak.sd/customer/report/'+this.state.item.id+"?start="+this.state.start+"&end="+this.state.end)
              this.setState({report:false})}}>تنفيذ</Button>
        </DialogActions>
      </Dialog>
  <Dialog open={this.state.addModal} onClose={()=>this.setState({addModal:false})}>
        <DialogTitle>اضافة عميل</DialogTitle>
        <DialogContent>

        <TextField  margin="dense" 
          value={this.state.code}
          onChange={(e)=>this.setState({code:e.target.value})}
           label="كود العميل"fullWidth variant="standard" />
         
          <TextField  margin="dense" 
          value={this.state.name}
          onChange={(e)=>this.setState({name:e.target.value})}
           label="اسم المتجر"fullWidth variant="standard" />
          <TextField  margin="dense" label="الاسم"
           value={this.state.person}
           onChange={(e)=>this.setState({person:e.target.value})}
           fullWidth variant="standard" />
          
          <TextField  margin="dense"
           value={this.state.mobile}
           onChange={(e)=>this.setState({mobile:e.target.value})}
           label="رقم الهاتف" fullWidth variant="standard" />
          <TextField  margin="dense" 
           value={this.state.mobile1}
           onChange={(e)=>this.setState({mobile1:e.target.value})}
          label="رقم الهاتف اخر" fullWidth variant="standard" />
          <TextField  margin="dense"
           value={this.state.address}
           onChange={(e)=>this.setState({address:e.target.value})}
           label="العنوان" fullWidth variant="standard" />

          <TextField  margin="dense" 
           value={this.state.bank}
           onChange={(e)=>this.setState({bank:e.target.value})}
          label="بنك العميل" fullWidth variant="standard" />

          <TextField  margin="dense" 
           value={this.state.account}
           onChange={(e)=>this.setState({account:e.target.value})}
          label="رقم الحساب" fullWidth variant="standard" />
          

        <FormControl variant="standard" margin="dense" fullWidth variant="standard" >
        <InputLabel id="demo-simple-select-standard-label">المنطقة</InputLabel>
        <Select
          value={this.state.area_id}
          onChange={(e)=>{this.setState({area_id:e.target.value})}}
          label="المنطقة"
        >
         {this.state.areas&&this.state.areas.map((item,key) =>{

         return <MenuItem key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({addModal:false})}}>الغاء</Button>
          <Button onClick={()=>{
            this.add()
          }}
          disabled={!this.state.name||!this.state.person||!this.state.address||!this.state.mobile||!this.state.area_id}
          >حفظ</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل عميل</DialogTitle>
        <DialogContent>

        <TextField  margin="dense" 
          value={this.state.customer.code}
          onChange={(e)=>{
            var customer=this.state.customer
            customer.code=e.target.value
            this.setState({customer:customer})
          }}
           label="كود العميل"fullWidth variant="standard" />
         
          <TextField  margin="dense" 
          value={this.state.customer.name}
          onChange={(e)=>{
            var customer=this.state.customer
            customer.name=e.target.value
            this.setState({customer:customer})
          }}
           label="اسم المتجر"fullWidth variant="standard" />
          <TextField  margin="dense" label="الاسم"
           value={this.state.customer.person}
           onChange={(e)=>{
             var customer=this.state.customer
             customer.person=e.target.value
             this.setState({customer:customer})}}
           fullWidth variant="standard" />
          <TextField  margin="dense"
           value={this.state.customer.mobile}
           onChange={(e)=>{
            var customer=this.state.customer
            customer.mobile=e.target.value
            this.setState({customer:customer})
           }}
           label="رقم الهاتف" fullWidth variant="standard" />
          <TextField  margin="dense" 
           value={this.state.customer.mobile1}
           onChange={(e)=>{
            var customer=this.state.customer
            customer.mobile1=e.target.value
            this.setState({customer:customer})
           }}
          label="رقم هاتف اخر" fullWidth variant="standard" />

          <TextField  margin="dense" 
           value={this.state.customer.bank}
           onChange={(e)=>{
            var customer=this.state.customer
            customer.bank=e.target.value
            this.setState({customer:customer})
           }}
          label="البنك" fullWidth variant="standard" />

          <TextField  margin="dense" 
           value={this.state.customer.account}
           onChange={(e)=>{
            var customer=this.state.customer
            customer.account=e.target.value
            this.setState({customer:customer})
           }}
          label="رقم الحساب" fullWidth variant="standard" />


          <TextField  margin="dense"
           value={this.state.customer.address}
           onChange={(e)=>{
            var customer=this.state.customer
            customer.address=e.target.value
            this.setState({customer:customer})
           }}
           label="العنوان" fullWidth variant="standard" />
          

        <FormControl variant="standard" margin="dense" fullWidth variant="standard" >
        <InputLabel id="demo-simple-select-standard-label">المنطقة</InputLabel>
        <Select
          value={this.state.customer.area_id}
          onChange={(e)=>{
            var customer=this.state.customer
            customer.area_id=e.target.value
            this.setState({customer:customer})
          }}
          label="المنطقة"
        >
         {this.state.areas&&this.state.areas.map((item,key) =>{

         return <MenuItem key={key} value={item.id}> {item.name}</MenuItem>
         })
         }
        </Select>
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({edit:false})}}>الغاء</Button>
          <Button onClick={()=>{
            this.update(this.state.customer)
          }}
          disabled={!this.state.customer.name||!this.state.customer.person||!this.state.customer.address||!this.state.customer.mobile||!this.state.customer.area_id}
          >حفظ</Button>
        </DialogActions>
      </Dialog>
      <div className="p-2 w-100">
            <div className=" main " style={{direction:"ltr"}} >
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({addModal:true})}>
   <AddCircle />
    </IconButton>
    :null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      value={this.state.input}
      onChange={(e)=>this.setState({input:e.target.value})}
      placeholder="Search "
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>
          
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid rows={this.state.customers.filter(item=>item.name.indexOf(this.state.input) >-1||item.code&&item.code.indexOf(this.state.input) >-1)}
         columns={this.state.columns} components={{ Toolbar: GridToolbar }} />
        </div>
            </div>

           </div>
            </>
        )
    }
}