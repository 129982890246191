import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { user } from "../../service";
import Area from "./Area";
import City from "./City";
import Line from "./Line";



export default class Location extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={view:0,user:null}
    }

    update(i)
    {
       
        this.setState({view:i})
    }

    view(i)
    {
        if(i==0)
        {
            return <City />
        }
        else if(i==1)
        {
            return <Area />
        }
        else if(i==2) {
            return <Line />
        }
    }

    render()
    {
        return (
            <div className="p-2 w-100">
            <div className="main">
                <Box className="p-2 ">
                <Tabs value={this.state.view} sx={{ borderBottom: 1, borderColor: 'divider' }} >
                    <Tab label="المدن" onClick={()=>this.update(0)}  />
                    <Tab label="المناطق" onClick={()=>this.update(1)}  />
                    <Tab label="الخطوط" onClick={()=>this.update(2)}  />
                </Tabs>
                </Box>
                <div className="">
                    {this.view(this.state.view)}
                </div>
            </div>
            </div>
        )
    }
}