import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import {  http, lines, loading,channels, products, getProducts } from "../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  AddCircle, Create } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';


export default class Products extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({all:[],list:[],input:"",name:"",price:"",add:false,edit:false,edit_city:{name:''}})
        this.getData()
       
    }
   

  async  search(e)
    {
      
      this.state.list=this.state.all.filter(item=>item.name.toLowerCase().indexOf(e.target.value) > -1)

     this.setState({list:this.state.list,input:e.target.value})
     
    }
    getData()
    {
        http.get("products").then(res=>{
            this.setState({all:res.data,list:res.data})
        })
    }
    componentDidMount()
    {
       
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('products',{name:this.state.name,price:this.state.price}).then(res=>{
            this.setState({name:"",price:""})
            getProducts()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("products/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
        getProducts()
       })
       .catch(e=>{
        loading.next(false)
        console.log(e)
    })
    }

    render()
    {
        return (<>
     
        <Dialog open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="name" fullWidth variant="standard"   />

        <TextField  margin="dense"  value={this.state.price}  
          onChange={(e)=>this.setState({price:e.target.value})}
           label="Price" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>Cancel</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>update </DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="name" fullWidth variant="standard"   />
           <TextField  margin="dense" value={this.state.edit_city.price}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.price=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="price" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>Cancel</Button>
          <Button onClick={()=>{
              http.put('products/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  getProducts()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>Save</Button>
        </DialogActions>
      </Dialog>
          
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      onChange={(e)=>this.search(e)}
      placeholder="Search "
      value={this.state.input}
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>

            <table className="table">
                <thead>
                    <tr>
                        <th>name</th>
                        <th>Price</th>
                        <th>active</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.list&&this.state.list.map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>{item.price} </td>
                            <td>
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            </td>
                            <td><IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton> </td>
                        </tr>)
                    })}
                </tbody>
            </table>
    
        </>)
    }
}