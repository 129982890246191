import React from "react";
import { http } from "../../service";


export default class Order extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state={order:null,id:window.location.pathname.replace("/orders/","")}
    }
    componentDidMount()
    {
        http.get('orders/'+this.state.id).then(res=>this.setState({order:res.data})).catch(e=>console.log(e))
    }


    render()
    {
        return(
            <div className="w-100">
                {this.state.order?
        <div className="p-2 w-100">

            <div className="main">
            <h1>بيانات الطلب</h1>
                <table className="table">
                    <thead>
                        <tr>
                            <th>رقم الطلب</th>
                            <th>كود الطلب</th>
                            <th>حالة الطلب</th>
                            <th>التوصيل</th>
                            <th>قيمة الطلب</th>
                        </tr>
                        <tr>
                            <td>{this.state.order.id} </td>
                            <td>{this.state.order.code} </td>
                            <td>
                                <div className="p-1 text-center"
                                style={{color:this.state.order.status.color,background:this.state.order.status.bgcolor,borderRadius:"20px"}}>
                                 {this.state.order.status.name}
                                 </div>
                                  </td>
                                  <td>{this.state.order.delivery} </td>
                                  <td>{this.state.order.amount} </td>
                        </tr>

                    </thead>
                </table>
                <h2>العنوان</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>المندوب</th>
                            <th>العنوان</th>

                            <th>المنطقة</th>
                            <th>الخط</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>{this.state.order?.pilot?.name} </td>
                            <td>{this.state.order.address} </td>
                            <td>{this.state.order.area?.name} </td>
                            <td>{this.state.order.area?.line?.name} </td>
                        </tr>
                    </tbody>
                </table>

                <h2>بيانات التاجر</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>كود التاجر </th>
                            <th>اسم المتجر </th>
                            <th>شخص للتواصل </th>
                            <th>رقم الهتاف </th>
                            <th>العنوان </th>
                            <th>رقم الحساب</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.state.order.customer.code} </td>
                            <td>{this.state.order.customer.name} </td>
                            <td>{this.state.order.customer.person} </td>
                            <td>{this.state.order.customer.mobile} </td>
                            <td>{this.state.order.customer.code} </td>
                            <td>{this.state.order.customer.account} </td>
                        </tr>
                    </tbody>
                </table>

                <h1>ملاحظة</h1>
                <p>
                    {this.state.order.note}
                </p>
            </div>

        </div>
    :null}
    </div>
        )
    }
}