import { Box, Tabs,Tab } from "@mui/material";
import React from "react";
import Channels from "../../components/Channels";
import Products from "../../components/Products";
import Status from "../../components/Status";
import { http, user } from "../../service";




export default class Settings extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={view:0,user:null}
        http.get("auth/profile").then(res=>console.log(res.data)).catch(e=>console.log(e))
    }
    componentDidMount()
    {
        user.subscribe(res=>this.setState({user:res}))
    }

    load(i)
    {
        if(i==0)
        {
            return <Channels />
        }
        if(i==1)
        {
            return <Products />
        }
        if(i==2)
        {
            return <Status />
        }
    }

    render()
    {
        return (
            <div className="p-2 w-100">
                {this.state.user&&this.state.user.role_id<3?
                <div className="main">
                <Box >
                    <Tabs value={this.state.view} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {['طرق الدفع','التوصيل','الحالة'].map((item,index)=>{
                            return (
                            <Tab key={index} label={item} onClick={()=>this.setState({view:index})}/>
                           )
                        })}
                    </Tabs>
                    </Box>
                    <div className="mt-2">
                        {this.load(this.state.view)}
                    </div>
                </div>
                :null}
            </div>
        )
    }
}