import React from "react";
import Select from 'react-select';
import { http, url } from "../../service";



export default class Report extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={customers:[],pilots:[],date:'',clist:[],plist:[],lines:[],lists:[]}
    }

    componentDidMount()
    {
        http.get("customers").then(res=>{
            var customers=[]
            res.data.map(item=>{
                customers.push({label:item.code+' '+item.name,value:item.id})
            })
            this.setState({customers:customers})
            console.log(customers)
        }).catch(e=>console.log(e))

        http.get("lines").then(res=>{
            var lines=[]
            res.data.map(item=>{
                lines.push({label:item.name,value:item.id})
            })
            this.setState({lines:lines})
            console.log(lines)
        }).catch(e=>console.log(e))

        http.get("pilots").then(res=>{
            var pilots=[]
            res.data.map(item=>{
                pilots.push({label:item.code+' '+item.name,value:item.id})
            })
            this.setState({pilots:pilots})
            
        }).catch(e=>console.log(e))
    }

    render()
    {
        return (

            <div className="p-2 w-100">
                <div className="main">
                    <h3>تقرير الطلبات </h3>

                   
                    <lable className="mt-4">الخط</lable>
                    <Select isMulti isRtl
                     onChange={(e)=>{
                         console.log(e)
                        this.setState({lists:e})
                    }}
                     options={this.state.lines}  />

                     <label>التاريخ</label>
                     <input className="form-control" type="date" 
                     onChange={(e)=>{
                         this.setState({date:e.target.value})
                     }}  
                     />

                     
                     <button className="btn btn-primary mt-2"
                     onClick={()=>{
                         var customers=[]
                         var lines=[]
                         this.state.clist.map((item,key)=>{
                             customers.push(item.value)
                         })
                         this.state.lists.map((item,key)=>{
                            lines.push(item.value)
                        })
                       // console.log(customers,pilots)
                        window.open(url+'order/report?date='+this.state.date
                        +"&lines="+lines)
                     }}
                     >موافق</button>
                </div>

            </div>
        )
    }
}