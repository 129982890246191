import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import {  http, lines, loading,channels } from "../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  AddCircle, Create } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';


export default class Channels extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({channels:[],list:[],input:"",name:"",account:"",add:false,edit:false,edit_city:{name:''}})
        channels.subscribe(res=>this.setState({channels:res}))
        this.getData()
        
    }
    getData()
    {
        http.get('channels').then(res=>{
            channels.next(res.data)
            this.setState({list:res.data})
            localStorage.setItem('channels',JSON.stringify(res.data))
        }).catch(e=>console.log(e))
    }

  async  search(e)
    {
      
      this.state.list=this.state.channels.filter(item=>item.name.toLowerCase().indexOf(e.target.value) > -1)

      this.setState({input:e.target.value,list:this.state.list})
     
    }
    componentDidMount()
    {
       
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('channels',{name:this.state.name,account:this.state.account}).then(res=>{
            this.setState({name:"",account:""})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("channels/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})
    }

    render()
    {
        return (<>
     
        <Dialog open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>انشاء</DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />

        <TextField  margin="dense"  value={this.state.account}  
          onChange={(e)=>this.setState({account:e.target.value})}
           label="الحساب" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل </DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />
           <TextField  margin="dense" value={this.state.edit_city.account}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.account=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="رقم الحساب" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.put('lines/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>
          
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      onChange={(e)=>this.setState({input:e.target.value})}
      placeholder="بحث "
      value={this.state.input}
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>

            <table className="table">
                <thead>
                    <tr>
                        <th>الاسم</th>
                        <th>رقم الحساب</th>
                        <th>الحالة</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.list&&this.state.list.filter(item=>item.name.indexOf(this.state.input) >-1).map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>{item.account} </td>
                            <td>
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            </td>
                            <td><IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton> </td>
                        </tr>)
                    })}
                </tbody>
            </table>
    
        </>)
    }
}