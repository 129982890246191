import { Backdrop, CircularProgress, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from "@mui/material";

import React from "react";
import {
  BrowserRouter as Router,
  Routes ,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Header from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { http, loading, menu, user,getProducts } from "./service";
import Customers from "./pages/Customers";
import { SideNave } from "./components/List";
import Location from "./pages/location/Location";
import Orders from "./pages/orders/Orders";
import Settings from "./pages/settings/Settings";
import Create from "./pages/orders/Create";
import SelectTable from "./components/SelectTable";
import Update from "./pages/orders/Update";
import Order from "./pages/orders/Order";
import Pilot from "./pages/Pilot";
import User from "./pages/User";
import Report from "./pages/orders/Report";
import AppOrders from "./pages/orders/AppOrders";
import AppUpdate from "./pages/orders/AppUpdate";


export default class  App extends React.Component {
  constructor(props)
  {
    super(props)
    this.state={loading:false,menu:false,user:null}
    getProducts()
    http.get('/customers')
    
    .then(res=>console.log(res))
    .catch(e=>console.log(e))
    
    if( !localStorage.getItem('user')&&window.location.pathname!="/login")
    {
      window.location.replace("/login")
    }

  }
  componentDidMount()
  {
    user.subscribe(res=>this.setState({user:res}))
    loading.subscribe(res=>this.setState({loading:res}))
    menu.subscribe(res=>this.setState({menu:res}))
  }
  componentWillUnmount()
  {
    // loading.unsubscribe()
    // menu.unsubscribe()
    // user.unsubscribe()
  }
  render()
  {
  return (
    <>
  
    <Router>
    <Header />
    <div className="d-flex">

    <SwipeableDrawer
            
            open={this.state.menu}
            onClose={()=>{menu.next(false)}}
            onOpen={()=>{}}
          >
           
           <SideNave />
          </SwipeableDrawer>
    
    <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.loading}

>
  <CircularProgress color="inherit" />
</Backdrop>

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Routes >
        <Route path="/login" element={ <Login />} />
         
        <Route path="/customers" element={ <Customers />} />
        <Route path="/location" element={ <Location />} />
        <Route path="/pilots" element={ <Pilot />} />
        <Route path="/orders" element={ <Orders />} />
        <Route path="/app/orders" element={ <AppOrders />} />
        <Route path="/app/update/:id" element={ <AppUpdate />} />
        <Route path="/users" element={ <User />} />
        <Route path="/orders/:id" element={ <Order />} />
        <Route path="/orders/create" element={ <Create />} />
        <Route path="/orders/report" element={ <Report />} />
        <Route path="/orders/update/:id" element={ <Update />} />
        <Route path="/settings" element={ <Settings />} />
        <Route path="/table" element={ <SelectTable colums={[{label:"name",key:'name'}]} data={[{name:"ali"}]}
         handler={(e)=>{console.log(e)}} />} />
        <Route path="/"  element={ <Home />} />
       
        </Routes >
      
   </div>
  </Router>
  </>
  );
        }
}


