import {  List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { menu } from "../service";
import { Link } from "react-router-dom";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { Accessibility, AppSettingsAlt, BarChart, Groups, GroupsSharp, Home, LocationCity, Person, Place, Settings, ShoppingBag, TwoWheeler } from "@mui/icons-material";

export class SideNave extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={add:false,edit:false}

    }

    render()
    {
        return(<div>
               
             <div style={{width:"300px"}} className="p-2" >
            <List>
            {[ {name:'',label:"لوحة التحكم",icon:<BarChart />}, {name:'customers',label:"التجار",icon:<GroupsSharp />},
            {name:'location',label:"الخطوط",icon:<Place />},
             {name:'orders',label:"الطلبات",icon:<ShoppingBag />},
             {name:'app/orders',label:"طلبات التطبيق",icon:<AppSettingsAlt />},
             {name:'orders/report',label:"تقرير الطلبات",icon:<DirectionsCarIcon />},
            {name:'settings',label:"الاعدادات",icon:<Settings />},
            {name:'pilots',label:"المناديب",icon:<TwoWheeler />},
            {name:'users',label:"مستخدمي النظام",icon:<Accessibility />}
        ].map((item, index) => (
            <Link to={'/'+item.name} key={index} onClick={()=>menu.next(false)} 
            style={{textDecoration:"none",color:"#4c5055"}}>
            <ListItem button style={{background:"#f3f3f3",borderRadius:"10px",margin:"5px auto",textAlign:"right"}} >

            <ListItemIcon>
            {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.label} />
            </ListItem>
            </Link>
            ))}
            </List>

            </div>
        </div>);
    }
}