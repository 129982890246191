import { CheckCircle, Close, Search } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";
import React from "react";



export default class SelectTable extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state={data:this.props.data.filter(item=>item.is_active=1),list:this.props.data,colums:this.props.colums,input:""}
    }

    search(e)
    {
        this.state.list=this.state.data 
        this.state.list=this.state.data.filter((item)=>{
            let result=false
                for(let key in item)
                {
                    if(item[key])
                    {
                    if( item[key].toString().toLowerCase().indexOf(e.target.value) >-1)
                    {
                        result=true
                    }
                }
                }
            return result
            
           
        })
        
        this.setState({list:this.state.list,input:e.target.value})
    }

    render()
    {
        return (
            <div className="bg-slate-50 w-100 p-2">
                <div className="d-flex bg-white my-1  w-100 rounded-xl  py-2 p-3">
                    {this.state.input?
                    <IconButton onClick={()=>{this.setState({input:"",list:this.state.data})}}>
                        <Close />
                    </IconButton>:null
                    }
                <InputBase
                value={this.state.input}
                onChange={(e)=>this.search(e)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="search"
        
      />
                    <button className="rounded-full">
                        <Search />
                    </button>

                    
                </div>
                <div className="p-2 mt-2 rounded-lg bg-white">
                    <div className="table-responsive">
                <table className="table">
                        <thead>
                            <tr>
                            <td></td>
                                {this.state.colums.map((item,key)=>{
                                    return (<th key={key}>
                                        {item.label}
                                    </th>)
                                })}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.list.map((item,key)=>{
                                return(<tr key={key}>
                                    <td><IconButton onClick={()=>{this.props.handler(item)}}>
                                        <CheckCircle  />
                                        </IconButton></td>
                                    {this.state.colums.map((col,i)=>{
                                        return (<td key={i}>
                                            {item[col.key]}
                                        </td>)
                                    })}
                                    
                                </tr>)
                            })}
                        </tbody>
                    </table>
                    </div>
                    </div>
            </div>
        )
    }
}