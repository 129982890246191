import { AddCircle, Create, Delete, Directions, Visibility, WhatsApp } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, InputBase, Paper, Tab, Tabs } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import { http, orders, user } from "../../service";
import { Link } from "react-router-dom";
import moment from "moment";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Select from 'react-select';


export default class AppOrders extends React.Component
{
   
    constructor(props)
    {
      
        super(props)
        this.state={perPage:15,
          user:null,orders:[],
          menu:null,
          pilots:[],
          pilot:"",
          customers:[],
          customer:"",
          order_id:'',
          list:[],
          name:"",
          status:"",
          input:"",
          lines:[],
          line:"",
          tab:0,statuses:[],columns: [
            { field: 'id', headerName: 'الرقم',width:180,renderCell:(data)=>(
              <>
              
              <button className="rounded-3xl p-1" 
             >
                {data.row.id} </button>
                {this.state.user&&this.state.user.role_id<3?
                <>

              <Link to={"/app/update/"+data.row.id}>
                <IconButton> 
                  <Create  />
                </IconButton>
                </Link>
               
                <IconButton color="error" onClick={(e)=>{
                  http.delete('orders/'+data.row.id).then(res=>this.getData())
                }}> 
                  <Delete  />
                </IconButton>
                </>
                :null}

                <Link to={"/orders/"+data.row.id}>
                <IconButton> 
                  <Visibility />
                </IconButton>
                </Link>
                
                </>
            ) },
            { field: 'name', headerName: 'المتجر',minWidth:150,renderCell:(data)=>
            (<>{data.row?.customer?.name}
            <IconButton color="success"
             onClick={()=>{
              window.open("https://wa.me/249"+data.row.customer?.mobile.substring(1))
            }}>
              <WhatsApp />
            </IconButton>
            </>) 
            },
            { field: 'line', headerName: 'الخط',minWidth:70,renderCell: (params) => (
              <>
                {
                  params?.row?.area?.line?.name 
                } 
              </>
            ), },
     
            { field: 'code', headerName: 'الكود ' ,minWidth:40},
            { field: 'amount', headerName: 'قمية الطلب ',minWidth:100 },
            
            { field: 'delivery', headerName: 'التوصيل ' ,minWidth:100},
            { field: 'address', headerName: ' العنوان' ,minWidth:150},
            { field: 'mobile', headerName: 'رقم العميل',minWidth:150 },
           
            { field: 'area', headerName: 'المنطقة',minWidth:100,renderCell: (params) => (
              <>
                {
                  params?.row?.area?.name
                }
              </>
            ), },
            
           
          ]}
         
    }
    componentDidMount()
    {
     this.getData() 
     user.subscribe(res=>this.setState({user:res}))
    }

    getData()
    {
      http.get("statuses").then(res=>{
        this.setState({statuses:res.data})
      }).catch(e=>console.log(e))
      http.get("lines").then(res=>{
        var customers=[{label:"الكل",value:""}]
        res.data.map(item=>{
            customers.push({label:item.name,value:item.name})
        })
        this.setState({lines:customers})
      }).catch(e=>console.log(e))
      http.get("customers").then(res=>{
        var customers=[{label:"الكل",value:""}]
        res.data.map(item=>{
            customers.push({label:item.name,value:item.name})
        })
        this.setState({customers:customers})
      }).catch(e=>console.log(e))
      http.get("pilots").then(res=>{
        var customers=[{label:"الكل",value:""}]
        res.data.map(item=>{
            customers.push({label:item.name,value:item.name})
        })
        this.setState({pilots:customers})
      }).catch(e=>console.log(e))
      http.get("orders").then(res=>{
        var orders=res.data 
     //   console.log(res.data[0].created_at)
        for(let i=0;i<orders.length;i++)
        {
          orders[i].code=orders[i].code??""
          orders[i].code=orders[i].pilot_id??""
          orders[i].created_at=moment(orders[i].created_at).format('YYYY-MM-DD HH:mm')
        }
        this.setState({orders:orders})}).catch(e=>console.log(e))
    }

    componentWillUnmount()
    {

    }

    render(){

        return (
        <div className="p-2 w-100" >
        <div className="main">
          <div className="row mb-2">
            <div className="col-md-4 col-12">
            <lable className="">المتجر</lable>
                    <Select  isRtl
                     onChange={(e)=>{
                         console.log(e.label)
                        this.setState({customer:e.value})
                    }}
                     options={this.state.customers}  /> 
            </div>
            <div className="col-md-4 col-12">
            <lable className="mt-4">المندوب</lable>
                    <Select  isRtl
                     onChange={(e)=>{
                         console.log(e.label)
                        this.setState({pilot:e.value})
                    }}
                     options={this.state.pilots}  />
            </div>

            <div className="col-md-4 col-12">
            <lable className="mt-4">الخط</lable>
                    <Select  isRtl
                     onChange={(e)=>{
                         console.log(e.label)
                        this.setState({line:e.value})
                    }}
                     options={this.state.lines}  />
            </div>

          </div>
    
                    
        <Box className="mb-2 p-2 bg-white" style={{borderRadius:"15px"}}>
          
            <Tabs value={this.state.tab} sx={{ borderBottom: 1, borderColor: 'divider' }} >
              <Tab label="الكل" onClick={()=>{
                this.setState({status:"",tab:0})

              }} />
              {this.state.statuses.map((item,key)=>{
                return (<Tab key={key} label={item.name} onClick={()=>{
                  this.setState({status:item.id,tab:(key+1)})
                 
              }}  />)
              })}
                
            </Tabs>
            </Box>

            <Paper className="mx-auto my-2"
    
      sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
    >
      {this.state.user&&this.state.user.role_id<3?
      <Link to="/orders/create">
     <IconButton sx={{ p: '10px' }} aria-label="menu" >
     <AddCircle />
      </IconButton>
      </Link>:null
    }
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        value={this.state.input}
        onChange={(e)=>this.setState({input:e.target.value})}
        placeholder="بحث"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    
    </Paper>



       
        <DataGrid style={{minHeight:"600px",direction:'ltr'}} className="d-flex"
        
         pageSize={this.state.perPage}
         onPageSizeChange={(newPageSize) => this.setState({perPage:newPageSize})}
         rowsPerPageOptions={[15, 10, 20]}
        rows={this.state.orders.filter(item=>
          {
            if(item.is_pending==1)
            {
            if(this.state.pilot)
            {
              return  item.status_id.toString().indexOf(this.state.status) >-1&&item.customer.name.toString().indexOf(this.state.customer)>-1&&item.pilot?.name.toString().indexOf(this.state.pilot)>-1&&item.area.line.name.indexOf(this.state.line)>-1
            }
            else 
            {
              return  item.status_id.toString().indexOf(this.state.status) >-1&&item.customer.name.toString().indexOf(this.state.customer)>-1&&item.area.line.name.indexOf(this.state.line)>-1
            }
          }

       
          
          }
          )} 
        columns={this.state.columns}  />

    
       
        </div>
        </div>)
    }
}