import { LoginOutlined } from "@mui/icons-material";
import { Alert, Button, TextField } from "@mui/material";
import React from "react";

import { http, loading ,user,navigater} from "../service";
import axios from "axios";

export default class Login extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={email:"",password:"",errors:[]}
    }
    componentDidMount()
    {

    }

    componentWillUnmount()
    {

    }
    login()
    {
        loading.next(true)
        http.post("auth/login",this.state)
        .then(res=>{
            loading.next(false)
            axios.defaults.headers['Authorization']="Bearer "+res.data.token
            localStorage.setItem('token',res.data.token)
            user.next(res.data.user)
            localStorage.setItem('user',JSON.stringify(res.data.user))
            window.location.replace("/")
        })
        .catch(e=>{
            loading.next(false)
            if(e)
            {
            let error=[]
            for(let i in e?.response?.data )
            {error.push(e?.response?.data[i])}
            this.setState({errors:error})
            }
           
        })
    }

    render()
    {
        return (
            <>
            <div className="w-100  d-flex" style={{height:"100vh"}}>
            <div className="m-auto  shadow " style={{maxWidth:"450px",padding:"20px",background:"#FFF",borderRadius:"10px"}}>
                <img src="logo.jpg" className="d-block mx-auto my-2" style={{height:"150px",borderRadius:"50%"}} />
            <TextField className="w-100 mb-2" value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})}  label="البريد الالكتروني" type="email" variant="standard" />
            <TextField className="w-100 mb-2" value={this.state.password} onChange={(e)=>this.setState({password:e.target.value})}  label="كلمة المرور" type="password" variant="standard" />

            <Button onClick={()=>this.login()} variant="contained" className="mx-auto my-2 d-block shadow"> 
            <LoginOutlined className="me-2" ></LoginOutlined> دخول</Button>
            {
                this.state.errors.map((item,key)=>{
                    return (
                        <Alert style={{borderRadius:"14px"}} key={key} className="mb-1" severity="error">{item}</Alert>
                    )
                })
            }
            </div>

            </div>
            </>
        )
    }
}