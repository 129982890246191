import { Button, InputLabel, MenuItem, TextField } from "@mui/material";
import React from "react";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import DialogTitle from '@mui/material/DialogTitle';
import { getCustomers, http } from "../../service";
import SelectTable from "../../components/SelectTable";
import { Add, AddCircle, AddRounded, Close, Delete } from "@mui/icons-material";
import '../../Rtl.css';
;


export default class Create extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state={code:"",customer_id:'',pilots:[],pilot_id:"",area_id:"",delivery_date:"",view_areas1:false,mobile:"",mobile1:"",amount:0,
        address:"",customers:[],customer_list:[],areas:[],products:[],propduct_id:"",delivery:800,channels:[],channel_id:'',
        view_customers:false,customer:null,view_areas:false,delivery_area_id:"",client_address:"",client_name:"",
        client_mobile:"",client_mobile1:"",description:"",note:"",expected_delivery_date:"",pickup_date:"",items:[],
        statuses:[],status_id:2
      }
        
    }

    componentDidMount()
    {
      this.getCustomers()
    }

    selectCustomer(item){
      this.setState({
        customer_id:item.id,
        area_id:item.area_id,
        address:item.address,
        view_customers:false,
        customer:item
      })
    }


    getCustomers()
    {
        http.get("customers").then(res=>{
            this.setState({customers:res.data,customer_list:res.data})
        }).catch(e=>console.log(e))

        http.get("pilots").then(res=>{
          this.setState({pilots:res.data})
      }).catch(e=>console.log(e))
      http.get("statuses").then(res=>{
        this.setState({statuses:res.data})
    }).catch(e=>console.log(e))

        http.get("channels").then(res=>{
          this.setState({channels:res.data})
          console.log(this.state.channels)
      }).catch(e=>console.log(e))

        http.get("products").then(res=>{
          this.setState({products:res.data})
      }).catch(e=>console.log(e))

        http.get("areas").then(res=>{
          this.setState({areas:res.data})
      })
    }

    save()
    {
      http.post('orders',this.state).then(res=>{
        window.location.href='/orders'
      }).catch(e=>console.log(e))
    }

    setArea(item)
    {
      this.setState({area_id:item.id,view_areas:false})
    }

    render()
    {
        return (
          <>
            <Dialog open={this.state.view_customers} onClose={()=>this.setState({view_customers:false})} fullWidth>
          <DialogActions>
          <IconButton onClick={()=>{this.setState({view_customers:false})}} > <Close></Close></IconButton>
         
        </DialogActions>
        <DialogTitle className="text-center"><h1>التاجر</h1></DialogTitle>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"الشركة",key:'name'},
          {label:"رقم الهاتف",key:'mobile'},
          {label:"العنوان",key:'address'},
        ]} data={this.state.customers}
         handler={(e)=>{this.setState({customer_id:e.id,view_customers:false})}} />
        </DialogContent>
        
      </Dialog>

      <Dialog open={this.state.view_areas} onClose={()=>this.setState({view_areas:false})} fullWidth>
         
         <div className="flex">
          <IconButton onClick={()=>{this.setState({view_areas:false})}} > <Close></Close></IconButton>
         
        
        <DialogTitle className="text-center"><h1>المنطقة</h1></DialogTitle>
        </div>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"المنطقية",key:'name'},
        ]} data={this.state.areas}
         handler={(e)=>{this.setArea(e)}} />
        </DialogContent>
        
      </Dialog>

      <Dialog open={this.state.view_areas1} onClose={()=>this.setState({view_areas1:false})} fullWidth>
         
         <div className="flex">
          <IconButton onClick={()=>{this.setState({view_areas1:false})}} > <Close></Close></IconButton>
         
        
        <DialogTitle className="text-center"><h1>المنطقة</h1></DialogTitle>
        </div>
        <DialogContent className="w-100">

        <SelectTable colums={[
          {label:"المنطقية",key:'name'},
        ]} data={this.state.areas}
         handler={(e)=>{this.setState({area_id:e.id,view_areas1:false})}} />
        </DialogContent>
        
      </Dialog>
            <div className="w-100 p-2" style={{direction:"rtl",textAlign:"right"}}>
               
                <div className="main">
                <h2 className="my-3 font-bold"> اضافة طلب</h2>
                <label className="mt-1 ">  كود الطلب</label>
    <input className="form-control mb-2" placeholder="كود الطلب" value={this.state.code} onChange={(e)=>this.setState({code:e.target.value})} />
    <label className="mt-2">التاجر</label>
                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }} >
                  
      <select className="form-select" value={this.state.customer_id} onChange={(e)=>{
       this.setState({customer_id:e.target.value})
      }} >
        <option value=''>التاجر</option>
        {this.state.customers.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>
      
     
      
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={(e)=>{this.setState({view_customers:true})}}>
        <SearchIcon />
      </IconButton>
     
    </Paper>


  
    <div className="row">
      <div className="col-6">
    <label className="mt-1 ">رقم هاتف العميل</label>
    <input className="form-control" placeholder="رقم هاتف العميل" value={this.state.mobile} onChange={(e)=>this.setState({mobile:e.target.value})} />
    </div><div className="col-6">
    <label className="mt-1 ">رقم هاتف اخر العميل</label>
    <input className="form-control" placeholder="رقم هاتف اخر العميل" value={this.state.mobile1} onChange={(e)=>this.setState({mobile1:e.target.value})} />
    </div></div>
    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%',marginTop:".5rem" }}  >
      <select className="form-select" value={this.state.area_id} onChange={(e)=>{
        this.setState({area_id:e.target.value})
      }} >
        <option value=''>المنطقة</option>
        {this.state.areas.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>({item.line.name}) {item.name} </option>)
        })}
      </select>

      
      
     
      
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={(e)=>{this.setState({view_areas1:true})}}>
        <SearchIcon />
      </IconButton>
     
    </Paper>

    <label>المندوب</label>
    <select className="form-select mb-1" value={this.state.pilot_id} onChange={(e)=>{
        this.setState({pilot_id:e.target.value})
      }} >
        <option value=''>المندوب</option>
        {this.state.pilots.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>

      <label>حالة الطلب</label>
    <select className="form-select mb-2" value={this.state.status_id} onChange={(e)=>{
       this.setState({status_id:e.target.value})
      }} >
        <option value=''>حالة الطلب</option>
        {this.state.statuses.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>


      <label> نوع التوصيل</label>
    <select className="form-select mb-2" value={this.state.propduct_id} onChange={(e)=>{
      var product=this.state.products.filter(item=>item.id==e.target.value)[0]

       this.setState({propduct_id:e.target.value,delivery:product.price})
      }} >
        <option value=''> نوع التوصيل</option>
        {this.state.products.filter(item=>item.is_active==1).map((item,key)=>{
          return(<option key={key} value={item.id}>{item.name}</option>)
        })}
      </select>



    <label className="mt-1 ">عنوان العميل</label>
    <input className="form-control" placeholder="العنوان" value={this.state.address} onChange={(e)=>this.setState({address:e.target.value})} />

 
  
    <table className="table">
      <thead>
        <tr>
          <th>رسوم التوصيل</th>
          <th>قيمة الطلب</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
          <input className="form-control" value={this.state.delivery} type="number" 
          onChange={(e)=>this.setState({delivery:e.target.value})} />
          </td>
          <td>
            <input className="form-control" value={this.state.amount} type="number" onChange={(e)=>this.setState({amount:e.target.value})} />
          </td>
          
        </tr>
      </tbody>
    </table>
    <label className="mt-2">ملاحظات</label>
    <textarea className="form-control " value={this.state.note}  onChange={(e)=>{this.setState({note:e.target.value})}}></textarea>

            <button
            onClick={()=>this.save()}
             disabled={!this.state.area_id||!this.state.address||this.state.delivery<1||!this.state.mobile||!this.state.customer_id}
             className="py-1 my-2 px-3 bg-slate-500 hover:bg-slate-600 rounded-md text-white">انشاء</button>
                </div>
            </div>
            </>
        )
    }
}