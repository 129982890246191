import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import { cities, http, loading, user } from "../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircle, Create, Delete, Password, Restore } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

export default class User extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({users:[],name:"",code:"",email:"",password:"",role_id:1,reset:false,npass:"",delete:false,
        line_id:"",lines:[],account:"",account:"",roles:[{id:1,name:"مدير النظام"}
        ,{id:2,name:"مشرف"},{id:3,name:"محاسب "},],
        mobile:"",mobile1:"",address:"",user:null
        ,input:"",pilots:[],add:false,edit:false,edit_city:{name:''}})
       
        
    }
    getData()
    {
        http.get('users').then(res=>{
            this.setState({users:res.data})
        }).catch(e=>console.log(e))
    }
    componentDidMount()
    {
      user.subscribe(res=>this.setState({user:res}))
      this.getData()
      http.get('users').then(res=>{
        this.setState({lines:res.data})
    }).catch(e=>console.log(e))
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('users',this.state).then(res=>{
            this.setState({name:""})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("users/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})

    }

    render()
    {
        return (<>
     
        <Dialog  open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>اضافة مستخدم</DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.email}  
          onChange={(e)=>this.setState({email:e.target.value})}
           label=" الايميل" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.password}  type="password" 
          onChange={(e)=>this.setState({password:e.target.value})}
           label=" كلمة المرور" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.mobile}  
          onChange={(e)=>this.setState({mobile:e.target.value})}
           label="رقم الهاتف" fullWidth variant="standard"   />


           <label>الصلاحية</label>
           <select className="form-select"  value={this.state.role_id} 
           onChange={(e)=>this.setState({role_id:e.target.value})}>
            
             {this.state.roles.map((item,key)=>{
            return   <option key={key} value={item.id} >{item.name} </option>
             })}
           </select>

        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل مستخدم </DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />


     

      <TextField  margin="dense"  value={this.state.edit_city.email}  
          onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.email=e.target.value 
            this.setState({edit_city:edit})
        }}
           label=" البريد الالكتروني" fullWidth variant="standard"   />

        

          <TextField  margin="dense"  value={this.state.edit_city.mobile}  
           onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.mobile=e.target.value 
            this.setState({edit_city:edit})
        }}
           label="رقم الهاتف" fullWidth variant="standard"   />

        

           <label>الصلاحية</label>
           <select className="form-select" value={this.state.edit_city.role_id}  onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.role_id=e.target.value 
              this.setState({edit_city:edit})
          }}>
            
             {this.state.roles.map((item,key)=>{
            return   <option key={key} value={item.id} >{item.name} </option>
             })}
           </select>


          


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>الغاء</Button>
          
          <Button onClick={()=>{
              http.put('users/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>


      <Dialog open={this.state.reset} onClose={()=>this.setState({reset:false})}>
        <DialogTitle>تعيين كلمة مرور </DialogTitle>
        <DialogContent>
        
         
      <TextField  margin="dense"  value={this.state.npass}  
          onChange={(e)=>{
            var edit=this.state.edit_city 
          
            this.setState({npass:e.target.value})
        }}
        type="password"
           label="كلمة المرور" fullWidth variant="standard"   />

        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({reset:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.post('users/reset/'+this.state.edit_city.id,{password:this.state.npass})
              .then(res=>{
                this.setState({reset:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({reset:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.delete} onClose={()=>this.setState({delete:false})}>
        <DialogTitle>  حذف مستخدم </DialogTitle>
        <DialogContent>
        <p>هل انت متاكد من حذف {this.state.edit_city.name} ?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({delete:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.delete('users/'+this.state.edit_city.id)
              .then(res=>{
                this.setState({delete:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({delete:false,edit_city:{name:""}})
            })
          }}>حذف</Button>
        </DialogActions>
      </Dialog>



      <div className="p-2 w-100">
        <div className="main">
           
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>:null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      value={this.state.input}
      onChange={(e)=>this.setState({input:e.target.value})}
      placeholder="بحث"
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>
            <table className="table">
                <thead>
                    <tr>
                        <th>الاسم</th>
                        <th> البريد الالكتروني </th>
                        <th>رقم الهاتف </th>
                        <th> الصلاحية </th>
                        <th>الحالة </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.users.filter(item=>item.name.indexOf(this.state.input) >-1)
                    .map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>{item.email} </td>
                            <td>{item.mobile} </td>
                            <td>{item.role?.name} </td>
                            <td>
                            {this.state.user&&this.state.user.role_id<2?
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />:null}
                            </td>
                            <td>
                            {this.state.user&&this.state.user.role_id<2?
                              <>
                              <IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton>
                                 <IconButton 
                            color="secondary"
                            onClick={()=>{this.setState({reset:true,edit_city:item})}}>
                                 <Restore /> </IconButton>
                                 <IconButton 
                            color="error"
                            onClick={()=>{this.setState({delete:true,edit_city:item})}}>
                                 <Delete /> </IconButton>
                                 </>:null}
                                  </td>
                        </tr>)
                    })}
                </tbody>
            </table>
            </div></div>
        </>)
    }
}