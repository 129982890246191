import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import {  http, lines, loading,channels, user } from "../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  AddCircle, Create } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';


export default class Status extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({channels:[],list:[],user:null,
          input:"",name:"",bgcolor:"#fff",
          color:"#000",add:false,edit:false,edit_city:{name:''}})
        
        this.getData()
        
    }
    getData()
    {
        http.get('statuses').then(res=>{
           
            this.setState({list:res.data,all:res.data})
            
        }).catch(e=>console.log(e))
    }

  async  search(e)
    {
      
      this.state.list=this.state.all.filter(item=>item.name.toLowerCase().indexOf(e.target.value) > -1)

      this.setState({input:e.target.value,list:this.state.list})
     
    }
    componentDidMount()
    {
       user.subscribe(res=>this.setState({user:res}))
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('statuses',{name:this.state.name,color:this.state.color,bgcolor:this.state.bgcolor}).then(res=>{
            this.setState({name:"",bgcolor:"#fff",color:"#000"})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("statuses/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})
    }

    render()
    {
        return (<>
     
        <Dialog open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>اضافة حالة</DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />
          
        <TextField  margin="dense" type="color"  value={this.state.bgcolor}  
          onChange={(e)=>this.setState({bgcolor:e.target.value})}
           label="لون الخلفية" fullWidth variant="standard"   />

        <TextField  margin="dense" type="color"  value={this.state.color}  
          onChange={(e)=>this.setState({color:e.target.value})}
           label="لون النص" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل حالة </DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />
           <TextField  margin="dense" value={this.state.edit_city.bgcolor}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.bgcolor=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="لون الخلفية" fullWidth type="color" variant="standard"   />

        <TextField  margin="dense" type="color" value={this.state.edit_city.color}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.color=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="لون الكتابة" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.put('statuses/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>
          
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>
    :null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      onChange={(e)=>this.search(e)}
      placeholder="Search "
      value={this.state.input}
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>

            <table className="table">
                <thead>
                    <tr>
                        <th>name</th>
                        <th>active</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.list&&this.state.list.map((item,key)=>{
                        return(<tr key={key}>
                            <td>
                                <div style={{color:item.color,background:item.bgcolor,borderRadius:'20px',padding:"5px 20px",textAlign:'center',width:"fit-content"}}>
                                {item.name}
                                </div>
                                 </td>
                           
                            <td>
                            {this.state.user&&this.state.user.role_id<3?
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />:null}
                            </td>

                            <td>
                            {this.state.user&&this.state.user.role_id<3?
                              <IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton> 
                                 :null}
                                 </td>
                        </tr>)
                    })}
                </tbody>
            </table>
    
        </>)
    }
}