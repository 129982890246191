import React from "react";
import { http, user,menu } from "../service";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AccountCircle, AccountCircleOutlined } from "@mui/icons-material";
export default class Header extends React.Component{
     pages = ['Products', 'Pricing', 'Blog'];
    settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
    constructor(props)
    {
        super(props)
        this.state={user:null,anchorEl:false,menu:false}
        user.subscribe(res=>this.setState({user:res}))
        menu.subscribe(res=>this.setState({menu:res}))
    }
    componentDidMount()
    {
       http.get('auth/profile').then(res=>{
         this.setState({user:res.data})
         localStorage.setItem('user',JSON.stringify(res.data))
       }).catch(e=>{
         this.logout()
         console.log(e)})
    }
    
    componentWillUnmount()
    {
        //  user.unsubscribe()
        //  menu.unsubscribe()
    }

    logout()
    {
        console.log("ok")
        http.defaults.headers["Authorization"]=null
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        user.next(null)
        if(window.location.pathname!='/login')
        {window.location.replace('/login')}
        
    }
    


    render()
    {
        return (
            <>
             {this.state.user && (
             <Box sx={{ flexGrow: 1 }}>
     
      <AppBar position="static">
        <Toolbar>
          <IconButton
          
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            className="mx-2"
            onClick={()=>menu.next(!this.state.menu)}
          >
            <MenuIcon  />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          jai3leak 
          </Typography>
         
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e)=>{this.setState({anchorEl:e.target})}}
                color="inherit"
              >
                <AccountCircleOutlined />
              </IconButton>
              <Menu
                id="menu-appbar"
                
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={()=>this.setState({anchorEl:null})}
              >
                <MenuItem >Profile</MenuItem>
                <MenuItem onClick={()=>this.logout()} >Logout</MenuItem>
              </Menu>
            </div>
          
        </Toolbar>
      </AppBar>

    </Box>
    )}
            </>
        )
    }
}