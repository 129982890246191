import { Button, TextField } from "@mui/material";
import React from "react";
import { http } from "../service";

export default class Home extends React.Component{
    constructor(props)
    {
        super(props)
        this.state={start:"",
        profit:0,profits:[],
        end:"",orders:[],order:{count:0,amount:0,delivery:0},areas:[],pilots:[],customers:[],statuses:[]}
    }

    componentWillMount()
    {
       this.getData()
    }

    getData()
    {
        http.get('dash?start='+this.state.start+"&end="+this.state.end).then(res=>{
            console.log(res.data)
            this.setState({
                orders:res.data.orders,
                order:res.data.order[0],
                areas:res.data.areas,
                pilots:res.data.pilots,
                customers:res.data.customers,
                start:res.data.start,
                end:res.data.end,
                statuses:res.data.status,
                profit:res.data.profit[0].total,
                profits:res.data.profits
            })
        }).catch(e=>console.log(e))
    }

    render()
    {
        return (
            <div className="w-100 p-3 rounded-2xl bg-gray-100 m-2">
                <div className="input-group mb-3">
                    <div className="form-group">
                        <label>البداية</label>
        <input className="form-control"  type="date" value={this.state.start} variant="standard" onChange={(e)=>this.setState({start:e.target.value})} />
      
      </div>
      <div className="form-group mx-1">
                        <label>النهاية</label>
        <input className="form-control "   type="date" 
        value={this.state.end} variant="standard"
         onChange={(e)=>this.setState({end:e.target.value})} />
        </div>
        <Button onClick={()=>this.getData()} className="mt-3" variant="contained">احسب</Button>

        </div>
       
        <div className=" bg-white rounded-xl w-20 h-20 p-4">
        <h2>الربح</h2>
        <strong>
            {new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.state.profit/2)}
        </strong>

        
        </div>

        <table className="table my-2">
            <tr>
                <th>التاريخ</th>
                <th>نصيب الشركة</th>
                <th>نصيب المناديب</th>
            </tr>
            {this.state.profits.map((item,key)=>{
                return <tr key={key}>
                    <td>{item.x}</td>
                    <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.total/2)}</td>
                    <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.total/2)}</td>
                </tr>
            })}
        </table>
            <h1>الطلبات</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>عدد الطلبات</th>
                        <th>اجمالي الطلبات</th>
                        <th>التوصيل</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{this.state.order.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.state.order.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(this.state.order.delivery)}</td>
                    </tr>
                </tbody>
            </table>

            </div>

            <h1>تفاصيل الطلبات</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                    <th> التاريخ</th>
                        <th>عدد الطلبات</th>
                        <th>اجمالي الطلبات</th>
                        <th>التوصيل</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.orders.map((item,key)=>{
                 return   <tr key={key}>
                     <td>{item.date}</td>
                        <td>{item.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery)}</td>
                    </tr>
                    })}
                </tbody>
            </table>
            </div>
            <h1>تفاصيل حالات الطلب</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                    <th> الحالة</th>
                        <th>عدد الطلبات</th>
                        <th>اجمالي الطلبات</th>
                        <th>التوصيل</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.statuses.map((item,key)=>{
                 return   <tr key={key}>
                     <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery)}</td>
                    </tr>
                    })}
                </tbody>
            </table>
            </div>

            <h1> العملاء</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                    <th> الاسم</th>
                        <th>عدد الطلبات</th>
                        <th> المبلغ</th>
                        <th>التوصيل</th>

                        <th>عدد الطلبات (راجع) </th>
                        <th> (راجع) المبلغ</th>
                        <th>(راجع) التوصيل</th>

                        <th>عدد الطلبات (تم التنفيذ) </th>
                        <th> (تم التنفيذ) المبلغ</th>
                        <th>(تم التنفيذ) التوصيل</th>

                        <th>عدد الطلبات (مؤجل) </th>
                        <th> (مؤجل) المبلغ</th>
                        <th>(مؤجل) التوصيل</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.customers.map((item,key)=>{
                 return   <tr key={key}>
                     <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery)}</td>

                        <td>{item.count1}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount1)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery1)}</td>

                        <td>{item.count2}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount2)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery2)}</td>

                        <td>{item.count3}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount3)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery3)}</td>
                    </tr>
                    })}
                </tbody>
            </table>
            </div>
            <h1> المناطق</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                    <th> الاسم</th>
                        <th>عدد الطلبات</th>
                        <th> المبلغ</th>
                        <th>التوصيل</th>

                        <th>عدد الطلبات (راجع) </th>
                        <th> (راجع) المبلغ</th>
                        <th>(راجع) التوصيل</th>

                        <th>عدد الطلبات (تم التنفيذ) </th>
                        <th> (تم التنفيذ) المبلغ</th>
                        <th>(تم التنفيذ) التوصيل</th>

                        <th>عدد الطلبات (مؤجل) </th>
                        <th> (مؤجل) المبلغ</th>
                        <th>(مؤجل) التوصيل</th>

                        <th>عدد الطلبات (جاري التنفيذ) </th>
                        <th> (جاري التنفيذ) المبلغ</th>
                        <th>(جاري التنفيذ) التوصيل</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.areas.map((item,key)=>{
                 return   <tr key={key}>
                     <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery)}</td>

                        <td>{item.count1}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount1)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery1)}</td>

                        <td>{item.count2}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount2)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery2)}</td>

                        <td>{item.count3}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount3)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery3)}</td>

                        <td>{item.count6}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount6)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery6)}</td>
                    </tr>
                    })}
                </tbody>
            </table>
            </div>
            <h1> المناديب</h1>
            <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                    <th> الاسم</th>
                        <th>عدد الطلبات</th>
                        <th> المبلغ</th>
                        <th>التوصيل</th>

                        <th>عدد الطلبات (راجع) </th>
                        <th> (راجع) المبلغ</th>
                        <th>(راجع) التوصيل</th>

                        <th>عدد الطلبات (تم التنفيذ) </th>
                        <th> (تم التنفيذ) المبلغ</th>
                        <th>(تم التنفيذ) التوصيل</th>

                        <th>عدد الطلبات (مؤجل) </th>
                        <th> (مؤجل) المبلغ</th>
                        <th>(مؤجل) التوصيل</th>

                        <th>عدد الطلبات (جاري التنفيذ) </th>
                        <th> (جاري التنفيذ) المبلغ</th>
                        <th>(جاري التنفيذ) التوصيل</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {this.state.pilots.map((item,key)=>{
                 return   <tr key={key}>
                     <td>{item.name}</td>
                        <td>{item.count}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery)}</td>

                        <td>{item.count1}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount1)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery1)}</td>

                        <td>{item.count2}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount2)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery2)}</td>

                        <td>{item.count3}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount3)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery3)}</td>

                        <td>{item.count6}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.amount6)}</td>
                        <td>{new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.delivery6)}</td>
                    </tr>
                    })}
                </tbody>
            </table>
            </div>
            </div>
        )
    }
}