import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import {  http, lines, loading, user } from "../../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  AddCircle, Create, Delete } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

export default class Line extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({lines:[],name:"",add:false,edit:false,edit_city:{name:''},input:"",user:null})
        this.getData()
        lines.subscribe(res=>this.setState({lines:res}))
    }
    getData()
    {
        http.get('lines').then(res=>{
            lines.next(res.data)
            localStorage.setItem('lines',JSON.stringify(res.data))
        }).catch(e=>console.log(e))
    }
    componentDidMount()
    {
        user.subscribe(res=>this.setState({user:res}))
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    add()
    {
        http.post('lines',{name:this.state.name}).then(res=>{
            this.setState({name:""})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("lines/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})

    }
    delete(item)
    {
      http.delete('lines/'+item.id).then(res=>this.getData())
    }
    render()
    {
        return (<>
     
        <Dialog open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>اضافة خط</DialogTitle>
        <DialogContent>
        
          <TextField autoFocus margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل خط </DialogTitle>
        <DialogContent>
        
          <TextField autoFocus margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.put('lines/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>
          
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
   <AddCircle />
    </IconButton>
    :null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      value={this.state.input}
      onChange={(e)=>this.setState({input:e.target.value})}
      placeholder="Search "
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>
            <table className="table">
                <thead>
                    <tr>
                        <th>الخط</th>
                        <th>الحالة</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.lines&&this.state.lines.filter(item=>item.name.indexOf(this.state.input) >-1)
                    .map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>
                            {this.state.user&&this.state.user.role_id<3?
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            :null}
                            </td>
                            {this.state.user&&this.state.user.role_id<3?
                            <td><IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton>
                                 <IconButton color="error" onClick={()=>this.delete(item)}>
                                   <Delete></Delete>
                                 </IconButton>
                                  </td>
                                  :<td></td>}
                        </tr>)
                    })}
                </tbody>
            </table>
    
        </>)
    }
}