import { Button, Divider, IconButton, InputBase, Paper, Switch } from "@mui/material";
import React from "react";
import { cities, http, loading, user } from "../service";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AddCircle, BarChart, Create, Delete, WhatsApp } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';

export default class Pilot extends React.Component{
    constructor(props)
    {
        super(props)
        this.state=({cities:[],name:"",code:"",report:"",start:"",end:"",item:null,
        line_id:"",lines:[],account:"",account:"",
        mobile:"",mobile1:"",address:"",user:null
        ,input:"",pilots:[],add:false,edit:false,edit_city:{name:''}})
       
        
    }
    getData()
    {
        http.get('pilots').then(res=>{
            this.setState({pilots:res.data})
        }).catch(e=>console.log(e))
    }
    componentDidMount()
    {
      this.getData()
      http.get('lines').then(res=>{
        this.setState({lines:res.data})
    }).catch(e=>console.log(e))
    user.subscribe(res=>this.setState({user:res}))
    }
    componentWillUnmount()
    {
      //  cities.unsubscribe()
    }

    delete(item)
    {
      http.delete('pilots/'+item.id).then(res=>this.getData())
    }

    add()
    {
        http.post('pilots',this.state).then(res=>{
            this.setState({name:""})
            this.getData()
        }).catch(e=>console.log(e))
    }
    toggle(item)
    {
        item.is_active=item.is_active==1?0:1
       loading.next(true)
       http.put("pilots/"+item.id,{is_active:item.is_active}).then(res=>{
        loading.next(false)
           this.getData()
       })
       .catch(e=>{
        loading.next(false)
           console.log(e)})

    }

    render()
    {
        return (<>

<Dialog  open={this.state.report} onClose={()=>this.setState({report:false})}>
        <DialogTitle>تقرير مندوب</DialogTitle>
        <DialogContent>
        
          <TextField type="date"  margin="dense"  value={this.state.start}  
          onChange={(e)=>this.setState({start:e.target.value})}  InputLabelProps={{
            shrink: true,
          }}
           label="البداية" fullWidth variant="standard"   />

          <TextField type="date"  margin="dense"  value={this.state.end}  
          onChange={(e)=>this.setState({end:e.target.value})}  InputLabelProps={{
            shrink: true,
          }}
           label="النهاية" fullWidth variant="standard"   />


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({report:false})}>الغاء</Button>
          <Button onClick={()=>{
             window.open('https://backend.jai3leak.sd/pilot/report/'+this.state.item.id+"?start="+this.state.start+"&end="+this.state.end)
              this.setState({report:false})}}>تنفيذ</Button>
        </DialogActions>
      </Dialog>
     
        <Dialog  open={this.state.add} onClose={()=>this.setState({add:false})}>
        <DialogTitle>اضافة مندوب</DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense"  value={this.state.name}  
          onChange={(e)=>this.setState({name:e.target.value})}
           label="الاسم" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.code}  
          onChange={(e)=>this.setState({code:e.target.value})}
           label="كود العميل" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.account}  
          onChange={(e)=>this.setState({account:e.target.value})}
           label="رقم الحساب" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.mobile}  
          onChange={(e)=>this.setState({mobile:e.target.value})}
           label="رقم الهاتف" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.address}  
          onChange={(e)=>this.setState({address:e.target.value})}
           label="العنوان" fullWidth variant="standard"   />

           <label>الخط</label>
           <select className="form-select"  value={this.state.line_id} 
           onChange={(e)=>this.setState({line_id:e.target.value})}>
             <option value="">الخط</option>
             {this.state.lines.filter(item=>item.is_active==1).map((item,key)=>{
            return   <option key={key} value={item.id} >{item.name} </option>
             })}
           </select>

        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({add:false})}>الغاء</Button>
          <Button disabled={!this.state.name} onClick={()=>{
              this.add()
              this.setState({add:false})}}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={this.state.edit} onClose={()=>this.setState({edit:false})}>
        <DialogTitle>تعديل مندوب </DialogTitle>
        <DialogContent>
        
          <TextField  margin="dense" value={this.state.edit_city.name}  
          onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.name=e.target.value 
              this.setState({edit_city:edit})
          }}
          label="الاسم" fullWidth variant="standard"   />


     

      <TextField  margin="dense"  value={this.state.edit_city.code}  
          onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.code=e.target.value 
            this.setState({edit_city:edit})
        }}
           label="كود العميل" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.edit_city.account}  
          onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.account=e.target.value 
            this.setState({edit_city:edit})
        }}
           label="رقم الحساب" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.edit_city.mobile}  
           onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.mobile=e.target.value 
            this.setState({edit_city:edit})
        }}
           label="رقم الهاتف" fullWidth variant="standard"   />

          <TextField  margin="dense"  value={this.state.edit_city.address}  
           onChange={(e)=>{
            var edit=this.state.edit_city 
            edit.mobile=e.target.value 
            this.setState({edit_city:edit})
        }}
           label="العنوان" fullWidth variant="standard"   />

           <label>الخط</label>
           <select className="form-select" value={this.state.edit_city.line_id}  onChange={(e)=>{
              var edit=this.state.edit_city 
              edit.line_id=e.target.value 
              this.setState({edit_city:edit})
          }}>
             <option value="">الخط</option>
             {this.state.lines.filter(item=>item.is_active==1).map((item,key)=>{
            return   <option key={key} value={item.id} >{item.name} </option>
             })}
           </select>


          


        </DialogContent>
        <DialogActions>
          <Button onClick={()=>this.setState({edit:false,edit_city:{name:""}})}>الغاء</Button>
          <Button onClick={()=>{
              http.put('pilots/'+this.state.edit_city.id,this.state.edit_city)
              .then(res=>{
                this.setState({edit:false,edit_city:{name:""}})
                  console.log(res);
                  this.getData()
            }).catch(e=>{
                console.log(e)
                this.setState({edit:false,edit_city:{name:""}})
            })
          }}>حفظ</Button>
        </DialogActions>
      </Dialog>

      <div className="p-2 w-100">
        <div className="main">
           
            <Paper className="mx-auto my-2"
    
    sx={{ p: '2px 4px', display: 'flex',borderRadius:"10px", alignItems: 'center', width: "100%" }}
  >
    {this.state.user&&this.state.user.role_id<3?
   <IconButton sx={{ p: '10px' }} onClick={()=>this.setState({add:true})}>
     
   <AddCircle />
    </IconButton>
    :null}
    <InputBase
      sx={{ ml: 1, flex: 1 }}
      value={this.state.input}
      onChange={(e)=>this.setState({input:e.target.value})}
      placeholder="بحث"
      inputProps={{ 'aria-label': 'search google maps' }}
    />
    <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
      <SearchIcon />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
  
  </Paper>
            <table className="table">
                <thead>
                    <tr>
                        <th>الاسم</th>
                        <th> الكود </th>
                        <th>الخط</th>
                        <th>رقم الهاتف </th>
                        <th>رقم الحساب </th>
                        <th>العنوان </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.pilots.filter(item=>item.name.indexOf(this.state.input) >-1)
                    .map((item,key)=>{
                        return(<tr key={key}>
                            <td>{item.name} </td>
                            <td>{item.code} </td>
                            <td>{item.line.name} </td>
                            <td> <IconButton color="success"
             onClick={()=>{
              window.open("https://wa.me/249"+item.mobile.substring(1))
            }}>
              <WhatsApp />
            </IconButton>{item.mobile} </td>
                            <td>{item.account} </td>
                            <td>{item.address} </td>
                            <td>
                            <Switch
                            color="success"
                            checked={item.is_active}
                            onChange={()=>this.toggle(item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            />
                            </td>

                            <td>
                            {this.state.user&&this.state.user.role_id<3?
                              <>
                              <IconButton 
                            color="warning"
                            onClick={()=>{this.setState({edit:true,edit_city:item})}}>
                                 <Create /> </IconButton>
                                 <IconButton color="error" onClick={()=>this.delete(item)}>
                                   <Delete></Delete>
                                 </IconButton>
                                 </>
                                 :null}
                                 <IconButton color="secondary" onClick={()=>this.setState({item:item,report:true})}>
                                   <BarChart />
                                 </IconButton>
                                  </td>
                        </tr>)
                    })}
                </tbody>
            </table>
            </div></div>
        </>)
    }
}