import axios from "axios";
import { BehaviorSubject } from "rxjs";
const user=new BehaviorSubject(JSON.parse(localStorage.getItem('user')??null));
const loading=new BehaviorSubject(false);
const menu=new BehaviorSubject(false);
const orders=new BehaviorSubject(JSON.parse(localStorage.getItem('orders')??null));
const cities=new BehaviorSubject(JSON.parse(localStorage.getItem('cities')??null));
const channels=new BehaviorSubject(JSON.parse(localStorage.getItem('channels')??null));
const areas=new BehaviorSubject(JSON.parse(localStorage.getItem('areas')??null));
const customers=new BehaviorSubject(JSON.parse(localStorage.getItem('customers')??null));
const lines=new BehaviorSubject(JSON.parse(localStorage.getItem('lines')??null));
const products=new BehaviorSubject(JSON.parse(localStorage.getItem('products')??null));
const http = axios.create({
  baseURL:  'https://backend.jai3leak.sd/api/',

  headers: {'X-Custom-Header': 'foobar','Authorization':"Bearer "+localStorage.getItem('token')}
});

const getCustomers=()=>{
  http.get('customers')
  .then(res=>customers.next(res.data))
  .catch(e=>console.log(e))
}

const getChannels=()=>{
  http.get('channels')
  .then(res=>channels.next(res.data))
  .catch(e=>console.log(e))
}

const getCities=()=>{
  http.get('cities')
  .then(res=>cities.next(res.data))
  .catch(e=>console.log(e))
}

const getAreas=()=>{
  http.get('areas')
  .then(res=>areas.next(res.data))
  .catch(e=>console.log(e))
}

const getProducts=()=>{
  http.get('products')
  .then(res=>products.next(res.data))
  .catch(e=>console.log(e))
}

const url='https://backend.jai3leak.sd/'

  export {
    url,
    user,
    loading,
    orders,
    http,
    menu,
    cities,
    areas,
    customers,
    lines,
    channels,
    products,
    getCustomers,
    getAreas,
    getCities,
    getChannels,
    getProducts
  }